var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x ",staticStyle:{"background":"linear-gradient(89.71deg, #BF1324 0.23%, #F95812 101.36%)"}},[_vm._m(0),_c('div',{staticClass:"kt-user-card__name align-self-center"},[_vm._v(_vm._s(_vm.$store.state.passport.user.name))]),_c('div',{staticClass:"kt-user-card__badge align-self-end"},[_c('v-btn',{attrs:{"text":"","dark":"","id":"boton-link-blanco"},on:{"click":_vm.cerrarSesion}},[_c('i',{staticClass:"flaticon-logout mr-2"}),_vm._v(" Cerrar sesión ")])],1)]),_c('div',{staticClass:"kt-notification"},[_c('div',{staticClass:"kt-notification__custom kt-space-between d-flex justify-space-between"},[_c('span',{staticStyle:{"font-family":"Roboto","font-weight":"400","color":"#8C8C8C"}},[_vm._v(" Cambiar tienda ")]),_c('v-bottom-sheet',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"#EFF8FF","id":"boton-tienda-dropdownuser2"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$store.state.passport.user.default_store_name)+" ")])]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-list',{class:'stores-list'},[_c('v-subheader',{attrs:{"dark":""}},[_c('span',{staticClass:"text-h6"},[_vm._v("Selecciona una tienda:")])]),_c('v-list-item',[_c('v-combobox',{attrs:{"clearable":"","items":_vm.$store.state.passport.user.availables_stores.map(function (e) { return ({text: e.name, value: e.id}); })},on:{"change":_vm.refreshUserDefaultStore},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var active = ref.active;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"text-subtitle-1",staticStyle:{"font-family":"Roboto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#f95812"}},[_vm._v("mdi-store")]),_vm._v(" "+_vm._s(item.text)+" ")]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.storeSelected),callback:function ($$v) {_vm.storeSelected=$$v},expression:"storeSelected"}})],1)],1)],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kt-user-card__avatar align-self-center"},[_c('span',{staticClass:"kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success",staticStyle:{"background":"white"}},[_c('i',{staticClass:"icon-user"})])])}]

export { render, staticRenderFns }