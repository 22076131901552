<template>
  <li class="kt-menu__section">
    <h4 style="font-size: 1rem !important; color: #ffffff;" class="kt-menu__section-text">{{ menu.section }}</h4>
    <i style="color: #ffffff" class="kt-menu__section-icon flaticon-more-v2"></i>
  </li>
</template>

<script>
export default {
  name: "KTMenuSection",
  components: {},
  props: {
    menu: Object
  }
};
</script>
